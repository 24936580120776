<!--
右侧滚动楼层组件说明》
1.自动获取dom模式
在需要滚动的页面增加 id=mainContent >>>>> 整个需要滚动的模块
模块内对应楼层div增加 data-title字段,作为楼层名称,增加class=modelBox为楼层定位
eg:
<div id='mainContent'>
  <div data-title='楼层1' class=modelBox>
    <div>内容</div>
  </div>
  <div data-title='楼层2' class=modelBox>
   <div>内容</div>
  </div>
</div>

2.传入scrollData楼层数据模式,
在需要滚动的页面增加 id为scrollBoxId字段,默认为mainContent； scrollBoxId字段也需要配在组件上 >>>>> 整个需要滚动的模块
模块内对应楼层div增加 class=modelBox为楼层定位
data内配置scrollData楼层数据 eg:scrollData:[{label:'xx',id:0},{label:'xx',id:1}]
 -->

<template>
  <div class="stairs" ref="stairs" :class="{ closeStatus: isActive }">
    <div class="open" v-if="isActive" @click="isActive = false">
      <i class="el-icon-d-arrow-left"></i>
    </div>
    <div v-if="!isActive">
      <div class="point">
        <div class="point-top"></div>
        <div
          v-for="(item, index) of point"
          :key="index"
          class="item"
          :class="{ itemactive: active === item.id }"
          @click="touchactive(item.id)"
        >
          <span class="label">{{ item.label }}</span>
        </div>
        <div class="scrollTop" @click="touchactive(0)">
          <i class="iconfont icontop"></i>
        </div>
        <div @click="isActive = true" class="close">
          {{ "收起" }} <i class="el-icon-d-arrow-right"></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    // 楼层数据
    scrollData: {
      type: Array,
      default: () => []
    },
    // 需要滚动的模块名
    scrollBoxId: {
      type: String,
      default: 'mainContent'
    }
  },
  data () {
    return {
      isActive: true,
      active: 0,
      point: [],
      scrollTop: 0
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.getOptions()
      window.addEventListener('scroll', this.handleScrollx, true)
    })
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScrollx, false)
  },
  methods: {
    // 判断楼层数据来源
    getOptions () {
      if (this.scrollData.length > 0) {
        this.point = JSON.parse(JSON.stringify(this.scrollData))
      } else {
        this.getStairs()
      }
    },
    // 自动获取楼层信息
    getStairs () {
      const opints = document.querySelectorAll('.modelBox')
      for (let i = 0; i < opints.length; i++) {
        this.point.push({ label: opints[i].dataset.title, id: i })
      }
    },
    touchactive (id) {
      this.active = id
      const jump = document.querySelectorAll('.modelBox')
      // 获取需要滚动的距离
      let total = ''
      total = jump[id].offsetTop - 195
      // Chrome
      this.$nextTick(() => {
        document.getElementById(this.scrollBoxId).scrollTop = total
      })
    },
    handleScrollx () {
      if (!document.getElementById(this.scrollBoxId)) {
        return false
      }
      const jump = document.querySelectorAll('.modelBox')
      if (this.scrollTop <= document.getElementById(this.scrollBoxId).scrollTop) {
        // 从上往下滚动
        this.scrollTop = document.getElementById(this.scrollBoxId).scrollTop
        if (this.scrollTop === 0) {
          this.active = 0
        }
        for (let i = 0; i < jump.length; i++) {
          jump[i].index = i
          if (
            this.scrollTop >= jump[i].offsetTop - 10 &&
            this.scrollTop <= jump[i].offsetTop + 180
          ) {
            for (let j = 0; j < jump.length; j++) {
              this.active = i + 1
            }
          }
        }
      } else if (
        this.scrollTop > document.getElementById(this.scrollBoxId).scrollTop
      ) {
        this.scrollTop = document.getElementById(this.scrollBoxId).scrollTop
        if (this.scrollTop === 0) {
          this.active = 0
        }
        for (let i = 0; i < jump.length; i++) {
          jump[i].index = i
          if (jump[i].offsetTop - this.scrollTop <= 0) {
            this.active = i + 1
          }
        }
      }

      clearTimeout(this.scrollxTime)
      this.scrollxTime = setTimeout(() => {
        // 节流
        // 获取需要滚动的距离
        // for (let i = 0; i < jump.length; i++) {
        //   if (scrollTop >= jump[i].offsetTop - 202) {
        //     this.active = i
        //   }
        //   if (i === 3) {
        //     if (scrollTop > jump[2].offsetTop - 10 && scrollTop < jump[2].offsetTop + 202) {
        //       this.active = 3
        //     }
        //   }
        //   if (i === 4) {.............
        //     if (scrollTop > jump[2].offsetTop + 202 && scrollTop < jump[2].offsetTop + 402) {
        //       this.active = 4
        //     }
        //   }
        // }
      }, 50)
    }
  }
}
</script>
<style lang="scss" scoped>
.stairs {
  position: absolute;
  right: -40px;
  top: 40%;
  display: flex;
  align-items: center;
  z-index: 9999;
  transform: translate(-40px, -25%);
  transition: 0.6s;
  .open {
    width: 10px;
    height: 126px;
    background: #C2D4FF;
    color: #757380;
    cursor: pointer;
    line-height: 126px;
    font-size: 10px;
    border-radius:10px 0px 0 10px ;
  }
}
// .closeStatus {
//   transform: translate(90px, -25%);
//   transition: 0.6s;
// }
.close {
  width: 110px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #757380;
  font-size: 12px;
  margin-top: 10px;
  background-color: #EBF1FF;
  cursor: pointer;
  position: relative;
  .el-icon-d-arrow-right {
    position: absolute;
    right: 8px;
    top: 10px;
  }
}
// 楼层样式
.point {
  border-bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  .item {
    box-shadow: 0 0 3px #fef3df;
    position: relative;

    &:hover {
      background: #EBF1FF;
      color: #4A7CF0;
    }
  }

  .point-top {
    width: 110px;
    height: 8px;
    background: #4A7CF0;
  }
  .scrollTop {
    width: 110px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
    font-size: 30px;
    font-weight: 300;
    color: #4A7CF0;
    background-color: #EBF1FF;
    .icontop {
      font-size: 30px;
    }
  }
  .item {

    width: 110px;
    min-height: 40px;
    // line-height: 40px;
    text-align: center;
    cursor: pointer;
    font-size: 12px;
    font-weight: 300;
    color: #757380;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .itemactive {
    flex-wrap: bold;
    color: #fff;
    &:hover {
      background: #EBF1FF;
      color: #FFFFFF;
    }
    .label {
      padding: 8px 15px;
      display: inline-block;
      background: #4A7CF0;
    }
  }
}
</style>
