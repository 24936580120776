var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"accessMake"},[_c('backtitle'),_c('div',{ref:"mainContent",staticClass:"mainContent",attrs:{"id":_vm.scrollBoxId}},[_c('div',{staticClass:"modelBox",attrs:{"data-title":"基本信息"}},[_c('text-title',{attrs:{"index":"01","label":"基本信息"}}),_c('interview-basic-info',{attrs:{"getCurrentData":_vm.getCurrentData}})],1),_c('div',{staticClass:"modelBox interviewRecord",attrs:{"data-title":"访谈记录"}},[_c('text-title',{attrs:{"index":"02","label":"访谈记录"}}),_c('base-form',{ref:"DataList",staticClass:"formStyle recordQuery",attrs:{"componentList":_vm.searchConfig,"showBtns":true,"formAttrs":{
          model: _vm.formData,
          labelWidth: '100px',
          'label-position': 'right',
        }},on:{"handleFilter":_vm.handleFilter,"clearParams":_vm.clearParams}}),_c('Interview-content',{attrs:{"getInterviewData":_vm.getInterviewData,"showMoreText":_vm.showMoreText},on:{"showMore":_vm.showMore}})],1),_c('div',{staticClass:"modelBox",attrs:{"data-title":"新的访谈记录"}},[_c('text-title',{attrs:{"index":"NEW","label":"新的访谈记录"}}),_c('div',{staticClass:"newConfig"},[_c('base-form',{ref:"formDataList",staticClass:"formStyle",attrs:{"componentList":_vm.newConfig,"showBtns":false,"formAttrs":{
            model: _vm.InterviewData,
            labelWidth: '100px',
          }}}),_c('div',{staticClass:"bottomBtn"},[_c('base-button',{attrs:{"label":"发 表"},on:{"click":function($event){return _vm.saveData('submit')}}}),_c('base-button',{attrs:{"label":"暂 存","type":"default"},on:{"click":function($event){return _vm.saveData('save')}}})],1)],1)],1)]),_c('div',{staticClass:"footer"},[_c('base-button',{attrs:{"label":"关 闭","type":"default"},on:{"click":_vm.close}})],1),_c('scroll-fool',{ref:"scrollFool",attrs:{"scrollData":_vm.scrollData,"scrollBoxId":_vm.scrollBoxId}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }