<template>
   <base-dialog
      :visible.sync="visibleDialog"
      :custom-class="'authDialog'"
      width="1200px"
      :showSubmit="true"
      :submitText="'保存'"
      @submit="submit"
      title="添加评论">
      <div class="content">
        <base-form
          :componentList="searchConfig"
          :showBtns="false"
          :formAttrs="{
            model: formData,
            labelWidth: '100px',
          }"
          class="formStyle"
          ref="formDataList"
        ></base-form>
      </div>
    </base-dialog>
</template>

<script>
import BaseForm from '@/components/common/base-form/base-form.vue'
import BaseDialog from '@/components/common/base-dialog/base-dialog.vue'
import { supplierApi } from '@/api/companyApi'
export default {
  components: { BaseDialog, BaseForm },
  props: {
    visible: Boolean,
    interviewId: String
  },
  data () {
    return {
      formData: {},
      searchConfig: [{
        label: '评论',
        prop: 'content',
        attrs: {
          placeholder: '请输入',
          type: 'textarea',
          rows: 5,
          maxlength: 2000,
          'show-word-limit': true
        },
        rules: [
          { required: true, message: '请输入', trigger: ['change, blur'] }
        ],
        span: 24

      }]
    }
  },
  // 计算属性 类似于data概念
  computed: {
    api () {
      return supplierApi
    },
    visibleDialog: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  // 监控data中的数据变化
  watch: {
    visibleDialog (val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.formDataList.clearValidate()
          this.formData = {}
        })
      }
    }
  },
  // 方法集合
  methods: {
    submit () {
      this.$refs.formDataList.validate((valid) => {
        if (valid) {
          this.api.addComments({ interviewId: this.interviewId, ...this.formData }).then(res => {
            if (res.data) {
              this.success('添加评论成功')
              this.$emit('success', { interviewId: this.interviewId, data: res.data })
              this.visibleDialog = false
            }
          })
        }
      })
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {},
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {}, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang="scss" scoped>
/deep/ .el-dialog--center{
  height: 40%;
}
/deep/ .el-form-item--small .el-form-item__error{
  padding-top: 90px;
}
</style>
