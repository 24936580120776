//访谈纪要制作---供应商
<template>
  <div class="accessMake">
    <backtitle></backtitle>
    <div class="mainContent" :id="scrollBoxId" ref="mainContent">
      <div data-title="基本信息" class="modelBox">
        <text-title index="01" label="基本信息"></text-title>
        <interview-basic-info :getCurrentData="getCurrentData"></interview-basic-info>
      </div>
      <div data-title="访谈记录" class="modelBox interviewRecord">
        <text-title index="02" label="访谈记录"></text-title>
        <!-- <base-button label="新增记录" class="addRecord"  @click="addRecord"></base-button> -->
        <!-- 搜索条件 -->
        <base-form
          :componentList="searchConfig"
          :showBtns="true"
          :formAttrs="{
            model: formData,
            labelWidth: '100px',
            'label-position': 'right',
          }"
          @handleFilter="handleFilter"
          @clearParams="clearParams"
          class="formStyle recordQuery"
          ref="DataList"
        ></base-form>
        <!-- 访谈内容 -->
        <Interview-content :getInterviewData="getInterviewData" @showMore="showMore" :showMoreText="showMoreText" />
      </div>
      <div  data-title="新的访谈记录" class="modelBox">
        <text-title index="NEW" label="新的访谈记录"></text-title>
        <div class="newConfig">
          <base-form
            :componentList="newConfig"
            :showBtns="false"
            :formAttrs="{
              model: InterviewData,
              labelWidth: '100px',
            }"
            class="formStyle"
            ref="formDataList"
          ></base-form>
          <div class="bottomBtn">
            <base-button
              label="发 表"
              @click="saveData('submit')"
            ></base-button>
            <base-button
              label="暂 存"
              type="default"
              @click="saveData('save')"
            ></base-button>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <!-- <base-button label="保 存" @click="saveData('save')"></base-button>
      <base-button label="提 交" @click="saveData('submit')"></base-button> -->
      <base-button label="关 闭" type="default" @click="close"></base-button>
    </div>
     <!-- 右侧楼层 -->
     <scroll-fool
      ref="scrollFool"
      :scrollData="scrollData"
      :scrollBoxId="scrollBoxId"
    />
  </div>
</template>
<script>
import ScrollFool from '@/components/packages/scroll-fool/scroll-fool.vue'
import InterviewContent from '../components/Interview-content.vue'
import TextTitle from '@/components/packages/text-title/text-title.vue'
import Backtitle from '@/components/packages/backtitle/backtitle.vue'
import baseButton from '@/components/common/button/base-button/base-button.vue'
import InterviewBasicInfo from '../components/Interview-basic-info.vue'
// import RespondentsInfo from '../components/respondents-info.vue'
import BaseForm from '@/components/common/base-form/base-form.vue'
import {
  interviewDetilsConfig,
  interviewDetilsConfigs,
  searchConfig,
  newConfig
} from '../utils/make-config'
// import fileGeneration from '../components/file-generation-and-download.vue'
// import { getBaseUserInfo } from '@/api/sys/user'
import { supplierApi } from '@/api/companyApi'
export default {
  components: {
    TextTitle,
    Backtitle,
    baseButton,
    InterviewBasicInfo,
    // selectPersonnel,
    // RespondentsInfo,
    BaseForm,
    InterviewContent,
    ScrollFool
  },
  props: {
    dictType: String
  },
  data () {
    return {
      scrollData: [{ label: '基本信息', id: 0 },
        { label: '访谈记录', id: 1 },
        { label: '新的访谈记录', id: 2 }
      ],
      scrollBoxId: 'RZJYscroll',
      InterviewData: { interviewersInfos: [], intervieweeInfos: [] },
      showMoreText: '点击加载更多',
      getInterviewData: [], // 访谈信息
      getCurrentData: {}, // 企业基本信息
      formData: {
        pageIndex: 1,
        pageSize: 3
      },
      activeNames: [0],
      checkOptions: [], // 范围选项
      checkList: [], // 选中的范围
      interviewRecordData: [
        {
          checkList: [],
          checkOptions: []
        }
      ],
      interviewDetailsData: {},
      fileMakeData: {}
    }
  },
  watch: {
    // dictType (val) {
    //   console.log(val, 'dictType')
    // }
  },
  computed: {

    api () {
      return supplierApi
    },
    newConfig () {
      return newConfig(this)
    },
    searchConfig () {
      return searchConfig(this)
    },
    subArr () {
      return new Map([
        ['save', '保存成功'],
        ['submit', '提交成功']
      ])
    },
    errArr () {
      return new Map([
        ['save', '保存失败'],
        ['submit', '提交失败']
      ])
    },
    interviewDetilsForm () {
      return (data, index) => {
        if (data.enterpriseName === 1) {
          return interviewDetilsConfig(this, index)
        } else {
          return interviewDetilsConfigs(this, index)
        }
      }
    }
  },
  created () {
    this.businessId = this.$route.query.businessId
  },
  mounted () {
    if (this.$route.query.keyId) {
      this.getCompanyByKeyId()
      this.handleFilter()
      this.getInterviewDraftInfo()
    }
  },
  methods: {
    // 获取暂存访谈纪要
    getInterviewDraftInfo () {
      this.api.getInterviewDraftInfo({ enterpriseId: this.$route.query.keyId, addUserId: JSON.parse(localStorage.getItem('userInfo')).userId }).then(res => {
        if (res.data) {
          if (res.data.interviewDate === '1900-01-01 00:00:00') {
            res.data.interviewDate = undefined
          }
          this.InterviewData = res.data
          if (this.InterviewData.intervieweeInfos) {
            this.InterviewData.intervieweeInfos.forEach((item, index) => {
              this.$set(this.InterviewData.intervieweeInfos[index], 'personnerName', item.userName)
              this.$set(this.InterviewData.intervieweeInfos[index], 'dutiesName', item.userPosition)
              this.$set(this.InterviewData.intervieweeInfos[index], 'showText', item.personnerName + '(' + item.dutiesName + ')')
            })
            this.InterviewData.interviewersInfos.forEach((item, index) => {
              this.$set(this.InterviewData.interviewersInfos[index], 'nickName', item.userName)
            })
          }
        }
      })
    },
    showMore () {
      this.formData.pageIndex = this.formData.pageIndex + 1
      this.getInterviewPage()
    },
    clearParams () {
      this.formData = this.$options.data().formData
    },
    handleFilter () {
      this.formData.pageIndex = 1
      this.getInterviewData = []
      this.getInterviewPage()
    },
    // 获取企业基本信息
    getCompanyByKeyId () {
      // 0:核心企业 1:供应商 2:项目公司 4:银行 5:签约主体 6:收款主体 8:保理商
      const typeArr = [{ key: 0, label: '核心企业' }, { key: 1, label: '供应商' }, { key: 2, label: '项目公司' }, { key: 4, label: '银行' }, { key: 5, label: '签约主体' }, { key: 6, label: '收款主体' }, { key: 8, label: '保理商' }]
      this.api.getCompanyByKeyId({ enterpriseId: this.$route.query.keyId }).then(res => {
        this.getCurrentData = res.data
        this.$set(this.getCurrentData, 'city', (res.data.registerProvince || '') + (res.data.registerCity || ''))
        if (res.data.enterpriseLabel !== '') {
          typeArr.forEach((item) => {
            if (item.key === res.data.enterpriseLabel) {
              this.$set(this.getCurrentData, 'enterpriseLabel', item.label)
            }
          })
        }
      })
    },
    // 获取访谈记录
    getInterviewPage () {
      this.showMoreText = '点击加载更多'
      const params = {
        enterpriseId: this.$route.query.keyId,
        addTimeStart: this.formData.addTime && this.formData.addTime.length > 0 ? this.formData.addTime[0] : '',
        addTimeEnd: this.formData.addTime && this.formData.addTime.length > 0 ? this.formData.addTime[1] : '',
        ...this.formData
      }
      this.api.getInterviewPage(params).then(res => {
        this.getInterviewData = this.getInterviewData.concat(res.data.records)
        if (this.getInterviewData.length >= res.data.total) {
          this.showMoreText = '已没有更多数据'
        }
      })
    },
    // 校验下数据
    validateData () {
      this.$refs.formDataList.validate((valid) => {})
    },
    // 新的访谈记录
    saveData (type) {
      let pass = true
      if (type === 'submit') {
        this.$refs.formDataList.validate((valid) => {
          if (!valid) {
            pass = false
          }
        })
        this.InterviewData.interviewStatus = 1
      } else if (type === 'save') {
        // 暂存时内容必填
        // this.$refs.formDataList.validateField(['interviewDetails'], valid => {
        //   if (valid) {
        //     pass = false
        //   }
        // })

        this.InterviewData.interviewStatus = 0
      }
      if (!pass) {
        this.warning('请完善访谈纪要信息')
        return false
      }
      if (this.InterviewData.intervieweeInfos && this.InterviewData.intervieweeInfos.length > 0) {
        // 受访人员信息 格式化
        this.InterviewData.intervieweeInfos.forEach((item) => {
          item.userName = item.personnerName
          item.userPosition = item.dutiesName
        })
      }
      if (this.InterviewData.interviewersInfos && this.InterviewData.interviewersInfos.length > 0) {
        // 访谈人员信息 格式化
        this.InterviewData.interviewersInfos.forEach((item) => {
          item.userName = item.nickName
        })
      }
      const data = {
        keyId: '',
        enterprise_id: this.$route.query.keyId,
        enterprise_name: this.getCurrentData.enterpriseName,
        addUserId: JSON.parse(localStorage.getItem('userInfo')).userId,
        ...this.InterviewData
      }
      this.api.addInterviewSave(data).then(res => {
        if (res.data) {
          this.success(type === 'submit' ? '发表成功' : '暂存成功')
          if (type === 'submit') {
            this.InterviewData = this.$options.data().InterviewData
            this.handleFilter()
            this.$nextTick(() => {
              this.$refs.formDataList.clearValidate()
            })
          }
        }
      })
    },
    // 添加访谈记录
    addRecord () {
      this.interviewRecordData.push({
        checkList: [],
        checkOptions: []
      })
    },
    close () {
      // this.$router.back()
      this.$router.push({
        path: '/CRM/interviewMinutesManagement'
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.newConfig {
  background: #f9f9f6;
  margin: 16px;
  padding-bottom: 31px;
  margin-bottom:43px ;

}
.recordQuery {
  /deep/ .isTopStyle {
    margin-top: 0;
    float: left;
    margin-left: 30px;
  }
}
.bottomBtn {
  text-align: center;
}
/deep/.el-form-item__label_new {
  padding-right: 10px !important ;
}
.accessMake {
  padding: 0px 10px;
  height: 100%;
  background: #f9f9f6;
  .mainContent {
    background: #fff;
    height: calc(100% - 105px);
    overflow-y: scroll;
  }
  .footer {
    text-align: center;
    margin: 0px 0 10px;
    padding: 14px 0;
    box-shadow: 0px 0px 13px 2px rgba(#4A7CF0, 0.2);
    background: #ffffff;
  }
}
.interviewRecord {
  position: relative;
  .addRecord {
    position: absolute;
    top: 15px;
    right: 20px;
  }
}
.respondentsDetils {
  padding-left: 20px;
  margin-top: 10px;
}
.desc {
  padding: 10px;
}
.smallTitle {
  font-size: 18px;
  color: #2862E7;
  margin-bottom: 20px;
}
.pd20 {
  padding: 20px;
}
/deep/ .el-form-item__content {
  height: auto !important;
}
.interviewDetils {
  /deep/ .el-form-item__label_new {
    font-size: 16px;
    color: #2862E7;
  }
}
</style>
