<template>
  <div class="interviewData">
    <div class="empty" v-if="interviewData.length === 0">
      <div class="noDataBg">
        <img :src="noDataBg" alt="" />
      </div>
      <div>暂无数据</div>
    </div>
    <div v-for="(item, index) of interviewData" :key="index">
      <div class="row">
        <div class="photo"></div>
        <div class="userName" style="color: #FFFFFF">
          {{ item.addUserName }}
        </div>
        <div class="time">
          {{ item.modifyTime }}发表了访谈纪要<span style="padding-left: 20px"
            >评论</span
          >({{ item.commentsInfo.length }})
        </div>
      </div>
      <div class="row personnel">
        <div style="padding-right: 160px;flex-shrink: 0;">
          访谈日期：{{ item.interviewDate }}
        </div>
        <div class="intervieweeNames">受访人员：<span v-html=" item.intervieweeNames "></span></div>
        <div class="interviewersNames">访谈人员：<span v-html=" item.interviewersNames"></span></div>
      </div>
      <!-- 搜索关键词 标签示例 <ins>关键字</ins> -->
      <div v-html="item.interviewDetails" class="content"></div>
      <div class="row button">
        <div class="row" @click="addComments(item.keyId)">
          <div><i class="iconfont iconpinglun"></i></div>
          <div>添加评论</div>
        </div>
        <div class="row" @click="download(item.interviewFile)">
          <div><i class="iconfont iconxiazai"></i></div>
          <div>附件下载</div>
        </div>
      </div>
      <!-- 评论 -->
      <div class="comment" v-if="item.commentsInfo.length > 0">
        <div v-for="(elem, indexOn) of item.commentsInfo" :key="indexOn">
          <div class="commentUserName">
            <span style="color: #2f90f4">{{ elem.addUserName }}</span>
            <span>{{ elem.addTime }}</span>
            <span>发表了评论</span>
          </div>
          <div class="commentContent">
            {{ elem.content }}
          </div>
        </div>
      </div>
    </div>
    <div class="more" @click="showMore" v-if="interviewData.length !== 0">
     <i class="iconfont iconspread-line" v-if="showMoreText==='点击加载更多'"></i> {{showMoreText}}
    </div>

    <comment-dialog
      :visible.sync="visible"
      :interviewId="interviewId"
      @success="addSuccess"
    />
  </div>
</template>

<script>
import { downFile } from '@/utils/devUtils'
import { downAPi } from '@/api/fileApi'
import CommentDialog from './comment-dialog.vue'
export default {
  components: { CommentDialog },
  props: {
    showMoreText: String,
    getInterviewData: Array
  },
  data () {
    return {
      noDataBg: require('@/assets/nodata.png'),
      interviewId: '', // 访谈纪要ID
      visible: false
    }
  },
  // 计算属性 类似于data概念
  computed: {
    interviewData () {
      return this.getInterviewData
    }
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    // 文件下载
    download (fileId) {
      if (!fileId) {
        this.warning('暂无文件')
        return false
      }
      downAPi
        .interviewDownload({ keyIds: fileId, transFileType: 'PDF' })
        .then((res) => {
          downFile(res)
        })
        .catch((e) => {
          this.error(e)
        })
    },
    // 评论添加成功
    addSuccess (data) {
      this.interviewData.forEach((item, index) => {
        if (data.interviewId === item.keyId) {
          this.interviewData[index].commentsInfo.unshift(data.data)
        }
      })
    },
    // 添加更多评论
    addComments (interviewId) {
      this.interviewId = interviewId
      this.visible = true
    },
    // 加载更多
    showMore () {
      if (this.showMoreText === '点击加载更多') {
        this.$emit('showMore', '')
      }
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {},
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {}, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang="scss" scoped>
.empty {
  width: 100%;
  height: 300px;
  padding-top:100px ;
  >div:nth-child(2){
    padding-top:15px ;
    text-align: center;
    color: #B6C2DF;

  }
  .noDataBg {
    width: 52px;
    height: 45px;
    margin: 0 auto;
    img{
      width: 100%;
      height: 100%;
    }
  }
}
.commentContent {
  word-wrap: break-word;
  word-break: break-all;
  color: #38373a;
  line-height: 30px;
  padding-bottom: 30px;
}
.button {
  font-weight: 400;
  color: #2862E7;
  font-size: 14px;
  padding: 30px 0;
  .row {
    padding-right: 50px;
    cursor: pointer;
    > div:nth-child(1) {
      padding-right: 10px;
    }
  }
}
.commentUserName {
  color: #acacb0;
  padding-bottom: 22px;
}
.comment {
  padding: 30px;
  background: #f8f8f8;
  margin-bottom: 30px;
  font-size: 14px;
  color: #38373a;
}
.content {
  font-size: 14px;
  color: #38373a;
  line-height: 30px;
  word-wrap: break-word;
  word-break: break-all;
}
/deep/ins {
  text-decoration: none !important;
  color: #ff5858;
}
.personnel {
  font-size: 14px;
  color: #38373a;
  width: calc(100% - 10px);
  border-bottom: 1px dotted #D9E5EE;
  padding: 19px 0;
  margin-bottom: 20px;

}
.interviewersNames{
  width: calc(100% - 850px);
  word-wrap: break-word;
  word-break: break-all;
}
.intervieweeNames{
  margin-right: 50px;
  width: calc(100% - 830px);
  word-wrap: break-word;
  word-break: break-all;
}
.photo {
  width: 32px;
  height: 32px;
  background: #7586b4;
  border-radius: 50%;
  margin-right: 20px;
  background-image: url("../../../../assets/layout/photo.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.userName {
  font-size: 14px;
  font-weight: 400;
  color: #38373a;
}
.time {
  font-size: 14px;
  color: #757380;
  padding-left: 20px;
}
.row {
  display: flex;
  align-items: flex-start;
}
.interviewData {
  border-top: 1px solid #D9E5EE;
  padding: 15px;
}
.more {
  text-align: center;
  cursor: pointer;
  color: #2862E7;
}
.content {
  .rightContent {
    width: 0;
    background: #f7fafb;
    padding: 10px;
    margin-left: 10px;
    flex: 1;

    .commentTime {
      text-align: right;
      border-bottom: 1px solid #bbbbbb;
      padding: 10px 0;
      margin-bottom: 10px;
    }
  }
}
</style>
